var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.isStudent
    ? _c(
        "div",
        {
          staticClass: "grid",
          class: [_vm.classes.layout, _vm.classes.padding],
        },
        _vm._l(_vm.contents, function (content, index) {
          return _c("s-multiplexed-card", {
            key: index,
            attrs: {
              item: _vm.isStudent ? content : _vm.getContentDetails(content),
            },
          })
        }),
        1
      )
    : _c(
        "div",
        {
          staticClass: "grid",
          class: [_vm.classes.layout, _vm.classes.padding],
        },
        _vm._l(_vm.contents, function (content, index) {
          return _c("t-multiplexed-card", {
            key: index,
            attrs: {
              item: _vm.isStudent ? content : _vm.getContentDetails(content),
            },
          })
        }),
        1
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }