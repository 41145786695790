var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-sheet",
    {
      class: { "d-flex align-center justify-center": _vm.loading },
      attrs: { "min-height": "200" },
    },
    [
      _vm.loading
        ? _c("v-progress-circular", {
            attrs: { indeterminate: "", color: "primary" },
          })
        : _vm.noContentCondition
        ? _c("no-content-component", {
            attrs: { message: "No course modules designed" },
          })
        : _c("v-container", { class: _vm.classes.container }, [
            _c(
              "div",
              {
                staticClass:
                  "primary--text xl:text-xl lg:text-xl md:text-lg sm:text-base",
              },
              [_vm._v(" Course Sections ")]
            ),
            _c("div", { staticClass: "d-flex flex-grow-1 py-6" }, [
              _c(
                "div",
                {
                  staticClass:
                    "d-flex flex-grow-1 overflow-x-auto overflow-y-hidden",
                },
                [
                  _vm.screenWidth < 600
                    ? _c("v-select", {
                        attrs: {
                          items: _vm.sections.map((section, i) => {
                            return {
                              text: `${section.title} (${section.contents.length})`,
                              value: i,
                            }
                          }),
                          dense: "",
                          outlined: "",
                        },
                        model: {
                          value: _vm.selected,
                          callback: function ($$v) {
                            _vm.selected = $$v
                          },
                          expression: "selected",
                        },
                      })
                    : _c(
                        "v-slide-group",
                        {
                          attrs: { mandatory: "", "show-arrows": "" },
                          model: {
                            value: _vm.selected,
                            callback: function ($$v) {
                              _vm.selected = $$v
                            },
                            expression: "selected",
                          },
                        },
                        _vm._l(_vm.sections, function (section, index) {
                          return _c("v-slide-item", {
                            key: index,
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function ({ active, toggle }) {
                                    return [
                                      _c(
                                        "v-btn",
                                        {
                                          staticClass:
                                            "mr-3 pa-2 d-flex align-center xl:text-sm lg:text-sm md:text-sm sm:text-xs",
                                          class:
                                            _vm.classes.slideItem.outer[active],
                                          attrs: {
                                            depressed: "",
                                            "input-value": active,
                                          },
                                          on: { click: toggle },
                                        },
                                        [
                                          _vm._v(
                                            " " + _vm._s(section.title) + " "
                                          ),
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "ml-2 rounded px-2 pa-1",
                                              class:
                                                _vm.classes.slideItem.inner[
                                                  active
                                                ],
                                            },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    section.contents.length
                                                  ) +
                                                  " "
                                              ),
                                            ]
                                          ),
                                        ]
                                      ),
                                    ]
                                  },
                                },
                              ],
                              null,
                              true
                            ),
                          })
                        }),
                        1
                      ),
                ],
                1
              ),
              _c(
                "div",
                {
                  staticClass: "d-flex align-center justify-space-between",
                  staticStyle: { width: "100px", height: "max-content" },
                },
                [
                  !_vm.fromStudentDashboard
                    ? _c(
                        "v-btn",
                        {
                          class: {
                            "controls-faded": _vm.unassignedSectionCondition,
                          },
                          attrs: { icon: "" },
                          on: {
                            click: function ($event) {
                              return _vm.toggleSectionEditDialog(_vm.selected)
                            },
                          },
                        },
                        [
                          _vm.saving
                            ? _c("v-progress-circular", {
                                attrs: {
                                  indeterminate: "",
                                  color: "primary",
                                  size: "20",
                                },
                              })
                            : _c("img", {
                                attrs: {
                                  src: _vm.icons.edit,
                                  alt: "edit-icon",
                                },
                              }),
                        ],
                        1
                      )
                    : _vm._e(),
                  !_vm.fromStudentDashboard
                    ? _c(
                        "v-btn",
                        {
                          class: {
                            "controls-faded": _vm.unassignedSectionCondition,
                          },
                          attrs: { icon: "" },
                          on: {
                            click: function ($event) {
                              return _vm.setupDelete(_vm.selected)
                            },
                          },
                        },
                        [
                          _vm.deleting
                            ? _c("v-progress-circular", {
                                attrs: {
                                  indeterminate: "",
                                  color: "orange",
                                  size: "20",
                                },
                              })
                            : _c("img", {
                                attrs: {
                                  src: _vm.icons.delete,
                                  alt: "delete-icon",
                                },
                              }),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
            ]),
            !_vm.fromStudentDashboard
              ? _c(
                  "div",
                  {
                    staticClass: "mt-2 overflow-x-hidden overflow-y-auto",
                    staticStyle: { "max-height": "45vh" },
                  },
                  [
                    _c("course-organization-expansion-panel", {
                      attrs: {
                        selected: _vm.selected,
                        "course-id": _vm.courseId,
                        "owner-id": _vm.ownerId,
                      },
                      on: {
                        update: function ($event) {
                          return _vm.$emit(`update`)
                        },
                      },
                    }),
                  ],
                  1
                )
              : _c(
                  "div",
                  {
                    staticClass: "mt-2 overflow-x-hidden overflow-y-auto",
                    staticStyle: { "max-height": "45vh" },
                  },
                  [
                    _c("course-organization-expansion-panel", {
                      attrs: {
                        selected: _vm.selected,
                        "course-id": _vm.courseId,
                        "owner-id": _vm.ownerId,
                      },
                    }),
                  ],
                  1
                ),
          ]),
      !_vm.fromStudentDashboard
        ? _c(
            "v-dialog",
            {
              attrs: { width: "unset" },
              model: {
                value: _vm.editSectionParams.dialog,
                callback: function ($$v) {
                  _vm.$set(_vm.editSectionParams, "dialog", $$v)
                },
                expression: "editSectionParams.dialog",
              },
            },
            [
              _c(
                "create-section-panel",
                _vm._g(
                  _vm._b(
                    {
                      model: {
                        value: _vm.outgoing,
                        callback: function ($$v) {
                          _vm.outgoing = $$v
                        },
                        expression: "outgoing",
                      },
                    },
                    "create-section-panel",
                    _vm.editSectionParams.attrs,
                    false
                  ),
                  _vm.editSectionParams.on
                )
              ),
            ],
            1
          )
        : _vm._e(),
      !_vm.fromStudentDashboard
        ? _c(
            "dialog-delete",
            _vm._g(
              _vm._b({}, "dialog-delete", _vm.deleteDialogParams.attrs, false),
              _vm.deleteDialogParams.on
            )
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }