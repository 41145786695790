<template>
  <div v-if="isStudent" class="grid" :class="[classes.layout, classes.padding]">
      <s-multiplexed-card
          v-for="(content, index) in contents"
          :key="index"
          :item="isStudent ? content : getContentDetails(content)"
      ></s-multiplexed-card>
  </div>
  <div v-else class="grid" :class="[classes.layout, classes.padding]">
    <t-multiplexed-card
        v-for="(content, index) in contents"
        :key="index"
        :item="isStudent ? content : getContentDetails(content)"
    ></t-multiplexed-card>
  </div>
</template>

<script>
import TMultiplexedCard from "#ecf/course-design/components/CourseOrganization/TMultiplexedCard.vue";
import SMultiplexedCard from "#ecf/course-design/components/CourseOrganization/SMultiplexedCard.vue";
import { CourseDesignUtils } from "#ecf/course-design/mixins";

export default {
  props: ["courseId", "moduleId"],
  components: { TMultiplexedCard, SMultiplexedCard },
  mixins: [CourseDesignUtils],
  computed: {
    isStudent() {
      return this.$route.path.includes("student");
    },
    contents() {
      if (!this.isStudent) {
        return this.$store.getters["design/modules"][this.courseId][
          this.moduleId
        ].contents;
      } else {
        let allModuleContents = this.$store.state.studentCourseDesignDashboard
          .moduleContents[this.$ielts_course_id];
        let module = this.$store.state.studentCourseDesignDashboard
          .courseModules[this.$ielts_course_id][this.moduleId];
        let moduleContentIds = [];
        module.contents.forEach(content => {
          moduleContentIds.push(content.id);
        });
        let moduleContents = moduleContentIds.reduce(
          (accumulator, currentValue) => [
            ...accumulator,
            allModuleContents[currentValue]
          ],
          []
        );
        return moduleContents;
      }
    },
    details() {
      return this.$store.getters["design/find"][this.courseId];
    },
    classes() {
      return {
        layout: `grid-cols-${this.breakPointValues(
          1,
          1,
          2,
          3
        )} grid-gap-${this.breakPointValues(3, 3, 3, 3)}`,
        padding: `px-${this.breakPointValues(
          2,
          3,
          6,
          6
        )} py-${this.breakPointValues(2, 2, 4, 4)}`
      };
    }
  },
  methods: {
    getContentDetails(content) {
      return this.details[content.id];
    }
  }
};
</script>
