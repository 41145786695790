var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-expansion-panels",
    { attrs: { flat: "", value: _vm.opened[_vm.selected] } },
    [
      _vm._l(_vm.selectedSection, function (module, index) {
        return _c(
          "v-expansion-panel",
          {
            key: index,
            staticClass: "mb-3 border border-solid border-primary",
            attrs: { "active-class": "panel-active" },
          },
          [
            _c("v-card", { staticClass: "panel-card", attrs: { flat: "" } }, [
              _c(
                "div",
                {
                  staticClass: "py-1 d-flex align-center",
                  class: _vm.classes.header,
                },
                [
                  _c(
                    "div",
                    {
                      staticClass: "d-flex flex-grow-1 clickable",
                      on: {
                        click: function ($event) {
                          return _vm.openSectionPanel(index)
                        },
                      },
                    },
                    [_vm._v(" " + _vm._s(_vm.moduleName(module)) + " ")]
                  ),
                  !_vm.isStudent
                    ? _c(
                        "div",
                        {
                          staticClass: "d-flex align-center",
                          class:
                            _vm.screenWidth < 600
                              ? "justify-end"
                              : "justify-space-between",
                          style: `width: ${_vm.sizes.actions}px`,
                        },
                        [
                          _c(
                            "v-btn",
                            {
                              staticClass:
                                "rounded-0 white black--text panel-action",
                              attrs: {
                                loading: _vm.editing,
                                depressed: "",
                                small: "",
                              },
                              on: {
                                click: () => {
                                  _vm.editModuleParams.id = module
                                  _vm.editModuleParams.outgoing =
                                    _vm.modules[module]
                                  _vm.editModuleParams.show = true
                                },
                              },
                            },
                            [_vm._v("Edit ")]
                          ),
                          _c(
                            "v-btn",
                            {
                              staticClass: "panel-action",
                              attrs: { icon: "" },
                              on: {
                                click: function ($event) {
                                  return _vm.removeModule(module)
                                },
                              },
                            },
                            [
                              _vm.deleting
                                ? _c("v-progress-circular", {
                                    attrs: {
                                      indeterminate: "",
                                      color: "orange",
                                      size: "20",
                                    },
                                  })
                                : _c("img", {
                                    attrs: {
                                      src: _vm.icons.delete,
                                      alt: "delete-icon",
                                    },
                                  }),
                            ],
                            1
                          ),
                          _c(
                            "v-btn",
                            {
                              attrs: { icon: "" },
                              on: {
                                click: function ($event) {
                                  return _vm.openSectionPanel(index)
                                },
                              },
                            },
                            [
                              _c(
                                "v-icon",
                                {
                                  staticClass: "panel-arrow",
                                  attrs: { size: "25" },
                                },
                                [_vm._v("mdi-chevron-right")]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                ]
              ),
              !_vm.isStudent
                ? _c(
                    "div",
                    {
                      staticClass:
                        "py-1 panel-action-small align-center justify-end",
                      class: _vm.classes.header,
                    },
                    [
                      _c(
                        "v-btn",
                        {
                          staticClass: "rounded-0 white black--text",
                          attrs: {
                            loading: _vm.editing,
                            depressed: "",
                            small: "",
                          },
                          on: {
                            click: () => {
                              _vm.editModuleParams.id = module
                              _vm.editModuleParams.outgoing =
                                _vm.modules[module]
                              _vm.editModuleParams.show = true
                            },
                          },
                        },
                        [_vm._v("Edit ")]
                      ),
                      _c(
                        "v-btn",
                        { attrs: { icon: "" } },
                        [
                          _vm.deleting
                            ? _c("v-progress-circular", {
                                attrs: {
                                  indeterminate: "",
                                  color: "orange",
                                  size: "20",
                                },
                              })
                            : _c("img", {
                                attrs: {
                                  src: _vm.icons.delete,
                                  alt: "delete-icon",
                                },
                              }),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ]),
            _c(
              "v-expansion-panel-content",
              { staticClass: "pa-0 expansion-content" },
              [
                _c("module-display-component", {
                  attrs: { "module-id": module, "course-id": _vm.courseId },
                }),
              ],
              1
            ),
          ],
          1
        )
      }),
      _c(
        "v-dialog",
        { attrs: { value: _vm.editModuleParams.show, width: "unset" } },
        [
          _c("create-module-panel", {
            attrs: {
              outgoing: _vm.editModuleParams.outgoing,
              "course-id": _vm.courseId,
              loading: _vm.editing,
              edit: true,
            },
            on: {
              save: _vm.editModule,
              update: (v) => (_vm.editModuleParams.outgoing = v),
              close: () => {
                _vm.editModuleParams.show = false
                _vm.editModuleParams.outgoing = {}
                _vm.editModuleParams.id = undefined
              },
            },
          }),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }