<template>
  <MBaseCardNew>
    <template v-slot:header>
      <MBaseCardHeaderNew
        :title="exam.exam_title || exam.title"
        :available-date="
          !$language
            ? 'Available From : ' + availableFrom
            : 'পরীক্ষা প্রকাশের তারিখ : ' +
              new Date(availableFrom).toLocaleString('bn-BD', {
                dateStyle: 'medium'
              })
        "
        :btn-name="!$language ? 'Exam History' : 'এক্সামের বিস্তারিত'"
        :element_type="'exam'"
        :should-enable-element_3="!!exam.is_attempted"
        @header_element_3="showHistory"
      ></MBaseCardHeaderNew>
    </template>

    <template v-slot:middle-content>
      <MBaseCardMiddleContent
        :element_1="availableUpTo"
        :element_2="exam.total_duration.toString()"
        :element_3="exam.total_points"
        :element_4="'N/A'"
        :element_type="'exam'"
      ></MBaseCardMiddleContent>
    </template>
    <template v-slot:footer>
      <MBaseCardFooter
        :element_1="!$language ? 'Details' : 'বিস্তারিত'"
        :element_2="!exam.is_attempted ? 'Start Exam' : 'Retry'"
        @footer_element_1="
          $router.push({
            name: 'entry-exam',
            params: {
              examId: exam.exam_id || exam.id
            }
          })
        "
        @footer_element_2="startExam"
      ></MBaseCardFooter>
    </template>
  </MBaseCardNew>
</template>

<script>
import MBaseCardNew from "~ef/components/MBaseCardNew.vue";
import MBaseCardHeaderNew from "~ef/components/MBaseCardHeaderNew.vue";
import MBaseCardMiddleContent from "~ef/components/MBaseCardMiddleContent.vue";
import MBaseCardFooter from "~ef/components/MBaseCardFooter.vue";

export default {
  name: "MExamListItem",
  components: {
    MBaseCardNew,
    MBaseCardHeaderNew,
    MBaseCardMiddleContent,
    MBaseCardFooter
  },
  props: {
    exam: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      loadingExam: false,
      loadingHistory: false
    };
  },
  computed: {
    availableUpTo() {
      if (!this.exam.end_date) return "Always";
      return new Date(this.exam.end_date)
        .toString()
        .split(" ")
        .splice(0, 4)
        .join(" ");
    },
    availableFrom() {
      return new Date(this.exam.start_date)
        .toString()
        .split(" ")
        .splice(0, 4)
        .join(" ");
    }
  },

  methods: {
    async startExam() {
      await this.$router.push({
        name: "entry-exam",
        params: { examId: this.exam.exam_id || this.exam.id }
      });
      /*if (this.loadingExam) return;
      try {
        this.loadingExam = true;
        const { student_exam_id: studentExamId } = await this.$store.dispatch(
          "studentExam/start",
          {
            examId: this.exam.exam_id ?? this.exam.id,
            batchId: this.$ielts_batch_id
          }
        );
        await this.$router.push({
          name: "studentsExamRoutes",
          params: { appear: "appear", testId: studentExamId }
        });
      } catch (e) {
        this.$logger.log(e);
        this.$root.$emit("alert", [undefined, e.message]);
      } finally {
        this.loadingExam = false;
      }*/
    },
    async showHistory() {
      try {
        this.loadingHistory = true;
        await this.$router.push({
          name: "studentsAppearExamRoutes",
          params: {
            appear: "history",
            testId: this.exam.is_attempted ? this.exam.exam_history_id : "N/A"
          }
        });
      } catch (e) {
        this.$logger.log(e);
        this.$root.$emit("alert", [undefined, e.message]);
      } finally {
        this.loadingHistory = false;
      }
    }
  }
};
</script>
