var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("MBaseCardNew", {
    scopedSlots: _vm._u([
      {
        key: "header",
        fn: function () {
          return [
            _c("MBaseCardHeaderNew", {
              attrs: {
                title: _vm.exam.exam_title || _vm.exam.title,
                "available-date": !_vm.$language
                  ? "Available From : " + _vm.availableFrom
                  : "পরীক্ষা প্রকাশের তারিখ : " +
                    new Date(_vm.availableFrom).toLocaleString("bn-BD", {
                      dateStyle: "medium",
                    }),
                "btn-name": !_vm.$language
                  ? "Exam History"
                  : "এক্সামের বিস্তারিত",
                element_type: "exam",
                "should-enable-element_3": !!_vm.exam.is_attempted,
              },
              on: { header_element_3: _vm.showHistory },
            }),
          ]
        },
        proxy: true,
      },
      {
        key: "middle-content",
        fn: function () {
          return [
            _c("MBaseCardMiddleContent", {
              attrs: {
                element_1: _vm.availableUpTo,
                element_2: _vm.exam.total_duration.toString(),
                element_3: _vm.exam.total_points,
                element_4: "N/A",
                element_type: "exam",
              },
            }),
          ]
        },
        proxy: true,
      },
      {
        key: "footer",
        fn: function () {
          return [
            _c("MBaseCardFooter", {
              attrs: {
                element_1: !_vm.$language ? "Details" : "বিস্তারিত",
                element_2: !_vm.exam.is_attempted ? "Start Exam" : "Retry",
              },
              on: {
                footer_element_1: function ($event) {
                  return _vm.$router.push({
                    name: "entry-exam",
                    params: {
                      examId: _vm.exam.exam_id || _vm.exam.id,
                    },
                  })
                },
                footer_element_2: _vm.startExam,
              },
            }),
          ]
        },
        proxy: true,
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }